import React, { useState } from "react";
import './styles.css';
import api from '../../services/api';

import { FiLogIn } from "react-icons/fi";
import { Link } from "react-router-dom";
import logoIshia from '../../assets/logo-ishia.jpg';
import { useNavigate } from "react-router-dom";

export default function Login() {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    
    


    async function logar(e) {
        e.preventDefault();
        const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_HMG_API_BASE_URL : process.env.REACT_APP_PRD_API_BASE_URL;

        console.log(process.env.NODE_ENV);
        console.log(baseURL);

        const data = {
            login,
            password
        };

        try {
            const response = await api.post('login', data);

            localStorage.setItem('login', login);
            localStorage.setItem('accessToken', response.data.token);

            navigate('/empresas');
        } catch (err) {
            alert('Login inválido. Tente novamente\n' + err.text);
        }
    }


    return (
        // <div className="login-container"></div>

        <section className="mb-5 mt-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="offset-md-3 col-md-6 col-lg-6">
                        <div className="card ">
                            <div className="card-header bg-dark">

                            </div>
                            <div className="card-body text-center">
                                <img src={logoIshia} alt="" height="100" width="200" />
                            </div>
                            <div className="card-body">
                                <form onSubmit={logar} method="post">
                                    <div className="form-group">
                                        <label for="email">Endereço de email</label>
                                        <input type="text" className="form-control @error('email') is-invalid @enderror @error('ativo') is-invalid @enderror" id="email" name="email"
                                            aria-describedby="emailHelp" placeholder="Seu email" autoFocus 
                                            value={login}
                                            onChange={e => setLogin(e.target.value)} />
                                        {/* <span className="invalid-feedback" role="alert"><strong>MESSAGE</strong></span> */}
                                        {/* <span className="invalid-feedback" role="alert"><strong>MESSAGE</strong></span> */}
                                    </div>
                                    <div className="form-group">
                                        <div className="float-left">
                                            <label for="password">Senha</label>
                                        </div>
                                        <div className="float-right">
                                            <Link className="button" to="/empresas">Empresas</Link>
                                            {/* <a href="#">Esqueceu a senha?</a> */}
                                        </div>
                                        <input type="password" className="form-control" id="password"
                                            placeholder="Senha" name="password" 
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}/>
                                    </div>
                                    <div className="form-group form-check">
                                        <div className="float-left">
                                            <input type="checkbox" className="form-check-input" id="remember" name="remember" />
                                            <label className="form-check-label" for="remember">Lembrar</label>
                                        </div>
                                        <div className="float-right">
                                            <button type="submit" className="btn btn-primary">
                                                <FiLogIn size={18} />   Entrar
                                            </button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="card-footer text-center bg-dark">
                                <p className="text-white font-weight-light">© Copyright 2019 - 2024. Todos os direitos reservados.</p>
                                <p className="text-white font-weight-light">Ishia Tecnologia & Consultoria - (33)9 9161-0065. Versão licenciada para CLIENTE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >

    );
}