import React from "react";

export default function Campo() {
    return (
        // @section('conteudo')
        <section class="card">
            <header class="card-header">
                <div class="card-actions">
                    <a href="#" class="card-action card-action-toggle" data-card-toggle></a>
                    <a href="#" class="card-action card-action-dismiss" data-card-dismiss></a>
                </div>
                <h2 class="card-title">Cadastrar Norma</h2>
            </header>
            <div class="card-body">
                <div class="col-md-12">
                    <form action="{{ route('normas.store') }}" method="post">
                        <div class="form-row">
                            <div class="form-group col-md-10">
                                <label for="descricao">Descrição</label>
                                <input type="text" class="form-control {{ $errors->has('descricao') ? 'is-invalid' : '' }}"
                                    id="descricao" name="descricao" placeholder="Ex.: NBR 10476" value="{{ old('descricao') }}" />
                                {/* @if($errors->has('descricao')) */}
                                <div class="invalid-feedback">
                                    {/* {{ $errors->first('descricao') }} */}
                                </div>
                                {/* @endif */}
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary">Salvar</button>
                    </form>
                </div>
            </div>

        </section>
        // @endsection

        // @section('script')
        //     <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.16/jquery.mask.min.js"></script>

        //     <script type="text/javascript">
        //         var maskBehavior = function (val) {
        //                 return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        //             },
        //             options = {
        //                 onKeyPress: function (val, e, field, options) {
        //                     field.mask(maskBehavior.apply({}, arguments), options);
        //                 }
        //             };

        //         $(document).ready(() => {
        //             $('#telefone').mask(maskBehavior, options);
        //         });
        //     </script>
        // @endsection

    );
}