import React from "react";

import { FiEdit, FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Campos() {
    return (
        <section class="card">

            <header class="card-header">
                <div class="card-actions">
                    <a href="#" class="card-action card-action-toggle" data-card-toggle></a>
                    <a href="#" class="card-action card-action-dismiss" data-card-dismiss></a>
                </div>
                {/* @if(Request::query('store') == 'sucesso')
                        @component('components.alertsucesso')
                            Registro gravado na base com sucesso!
                        @endcomponent
                    @elseif(Request::query('store') == 'falha')
                        @component('components.alertfalha')
                            Ocorreu uma falha durante a tentativa de gravação do registro na base!
                        @endcomponent
                    @endif */}
                <h2 class="card-title">Listar Campos</h2>
                <Link to="/campos/incluir" type="button"><FiPlusCircle /> Incluir</Link>

            </header>

            <div class="card-body">
                <div class="col-md-12">
                    <div class="row mb-2">
                        {/* @if(count($normas) > 0) */}
                        <table class="table table-hover table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* @foreach($normas as $n) */}
                                <tr>
                                    <th scope="row">1</th>
                                    <td>descrição da norma</td>
                                    <td>
                                        <form method="get" action="{{ route('normas.edit', $n->id) }}">
                                            <input type="hidden" name="id" value="{{ $n->id }}" />
                                            
                                            <button type="submit" class="btn btn-primary btn-sm text-white">
                                            <FiEdit size={15} />
                                            </button>
                                        </form>
                                    </td>
                                </tr>
                                {/* @endforeach */}
                            </tbody>
                        </table>
                        {/* @else */}
                        {/* <span class="text-danger">Nenhuma norma cadastrada. <a href="{{route('normas.create')}}">Clique aqui para cadastrar uma norma.</a></span> */}
                        {/* @endif */}
                    </div>
                </div>
            </div>
            <div class="card-footer">
            </div>
        </section>
    );
}