import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";

import { FiEdit, FiPlusCircle, FiTrash } from "react-icons/fi";

export default function Empresas() {
    const [empresas, setEmpresas] = useState([]);
    const [page, setPage] = useState(0);

    const token = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    useEffect(() => {
        obterMaisRegistros();
    }, []);

    async function obterMaisRegistros() {

        const response = api.get('api/empresas/v1', {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                page: page,
                size: 2,
                direction: 'asc'
            }
        });

        setEmpresas([...empresas, ...(await response).data.content]);

        setPage(page + 1);
    }

    async function logout() {
        localStorage.clear();
        navigate('/');
    }

    async function apagar(id) {
        try {
            await api.delete(`api/empresas/v1/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });

            setEmpresas(empresas.filter(empresa => empresa.id !== id));
        } catch (err) {
            alert('Falha ao apagar empresa ID ' + id);
        }
    }

    async function editar(id) {
        try {
            navigate(`/empresas/incluir/${id}`);
        } catch (err) {
            alert('Falha ao editar empresa ID ' + id);
        }
    }

    return (
        <section className="card">

            <header className="card-header">
                <div className="card-actions">
                    {/* <a href="#" className="card-action card-action-toggle" data-card-toggle></a> */}
                    {/* <a href="#" className="card-action card-action-dismiss" data-card-dismiss></a> */}
                </div>
                {/* @if(Request::query('store') == 'sucesso')
                        @component('components.alertsucesso')
                            Registro gravado na base com sucesso!
                        @endcomponent
                    @elseif(Request::query('store') == 'falha')
                        @component('components.alertfalha')
                            Ocorreu uma falha durante a tentativa de gravação do registro na base!
                        @endcomponent
                    @endif */}
                <h2 className="card-title">Listar Empresas</h2>
                <Link to="/empresas/incluir/0" type="button"><FiPlusCircle /> Incluir</Link>

            </header>

            <div className="card-body">
                <div className="col-md-12">
                    <div className="row mb-2">
                        {/* @if(count($normas) > 0) */}
                        <table className="table table-hover table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">CNPJ</th>
                                    <th scope="col">Razão Social</th>
                                </tr>
                            </thead>
                            <tbody>
                                {empresas.map(empresa => (
                                    <tr key={empresa.id} >
                                        <th scope="row">{empresa.id}</th>
                                        <td>{empresa.cnpj}</td>
                                        <td>{empresa.razaoSocial}</td>
                                        <td>

                                            <button onClick={() => editar(empresa.id)} type="button" className="btn btn-primary btn-sm text-white mr-1">
                                                <FiEdit size={15} />
                                            </button>
                                            <button onClick={() => apagar(empresa.id)} type="button" className="btn btn-danger btn-sm text-white">
                                                <FiTrash size={15} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        {/* @else */}
                        {/* <span className="text-danger">Nenhuma norma cadastrada. <a href="{{route('normas.create')}}">Clique aqui para cadastrar uma norma.</a></span> */}
                        {/* @endif */}
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <button className="button" onClick={obterMaisRegistros} type="button">Carregar Mais</button>
            </div>
        </section>
    );
}