import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api"


export default function Empresa() {

    const navigate = useNavigate();

    const [id, setId] = useState(null);
    const [cnpj, setCnpj] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [pathLogo, setPathLogo] = useState('/caminho/do/logo2.png');
    const [dadosEndereco, setDadosEndereco] = useState({
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: ''
    });
    const [telefone1, setTelefone1] = useState('');
    const [telefone2, setTelefone2] = useState(null);
    const [site, setSite] = useState('');
    const [email, setEmail] = useState('');

    // Função genérica para atualizar qualquer campo de dadosEndereco
    const handleEnderecoChange = (campo, valor) => {
        setDadosEndereco((prevDadosEndereco) => ({
            ...prevDadosEndereco,
            [campo]: valor
        }));
    }

    const {empresaId} = useParams();
    const token = localStorage.getItem('accessToken');

    async function carregarEmpresa() {
        try {
            const response = await api.get(`api/empresas/v1/${empresaId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setId(response.data.id);
            setCnpj(response.data.cnpj);
            setRazaoSocial(response.data.razaoSocial);
            setPathLogo(response.data.pathLogo);
            setDadosEndereco(response.data.dadosEndereco);
            setTelefone1(response.data.telefone1);
            setTelefone2(response.data.telefone2);
            setSite(response.data.site);
            setEmail(response.data.email);
        } catch (err) {
            alert('Erro ao carregar empresa ID' + empresaId);
            navigate('/empresas');
        }
    }

    useEffect(() => {
        if (empresaId === '0') return;
        else carregarEmpresa();
    }, [empresaId]);

    async function salvarOuAtualizar(e) {
        e.preventDefault();

        const data = {
            id,
            cnpj,
            razaoSocial,
            pathLogo,
            dadosEndereco,
            telefone1,
            telefone2,
            site,
            email
        };

        console.log(data);
        console.log(token);

        try {
            if (empresaId === '0') {
                await api.post('api/empresas/v1', data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            } else {
                await api.put('api/empresas/v1', data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
            navigate('/empresas')
        } catch (err) {
            alert('Erro ao gravar empresa!');
        }

    }

    return (
        // @section('conteudo')
        <section class="card">
            <header class="card-header">
                <div class="card-actions">
                    {/* <a href="#" class="card-action card-action-toggle" data-card-toggle></a> */}
                    {/* <a href="#" class="card-action card-action-dismiss" data-card-dismiss></a> */}
                </div>
                <h2 class="card-title">{empresaId === '0' ? 'Cadastrar ' : 'Editar '} Empresa: {razaoSocial}</h2>
            </header>
            <div class="card-body">
                <div class="col-md-12">
                    <form onSubmit={salvarOuAtualizar} method="post">
                        <div class="form-row">
                        <div class="form-group col-md-2">
                                <label for="cnpj">CNPJ</label>
                                <input type="text" class="form-control {{ $errors->has('razao') ? 'is-invalid' : '' }}"
                                    id="cnpj" name="cnpj" placeholder="CNPJ" value={cnpj} onChange={e => setCnpj(e.target.value)} />
                                {/* @if($errors->has('razao')) */}
                                <div class="invalid-feedback">
                                    {/* {{ $errors-> first('razao')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-10">
                                <label for="razao">Razão Social</label>
                                <input type="text" class="form-control {{ $errors->has('razao') ? 'is-invalid' : '' }}"
                                    id="razao" name="razao" placeholder="Razão Social" value={razaoSocial} onChange={e => setRazaoSocial(e.target.value)} />
                                {/* @if($errors->has('razao')) */}
                                <div class="invalid-feedback">
                                    {/* {{ $errors-> first('razao')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-1">
                                <label for="cep">CEP</label>
                                <input type="text" class="form-control {{ $errors->has('cep') ? 'is-invalid' : '' }}"
                                    id="cep" name="cep" placeholder="CEP" value={dadosEndereco.cep} onChange={e => handleEnderecoChange('cep', e.target.value)} />
                                {/* @if($errors->has('cep')) */}
                                <div class=" invalid-feedback">
                                    {/* {{ $errors-> first('cep')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-5">
                                <label for="logradouro">Logradouro</label>
                                <input type="text" class="form-control {{ $errors->has('logradouro') ? 'is-invalid' : '' }}"
                                    id="logradouro" name="logradouro"
                                    placeholder="Ex.: Rua Fulando Tal"
                                    value={dadosEndereco.logradouro} onChange={e => handleEnderecoChange('logradouro', e.target.value)} />
                                {/* @if($errors->has('logradouro')) */}
                                <div class="invalid-feedback">
                                    {/* {{ $errors-> first('logradouro')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-2">
                                <label for="bairro">Bairro</label>
                                <input type="text" class="form-control {{ $errors->has('bairro') ? 'is-invalid' : '' }}"
                                    id="bairro" name="bairro" placeholder="Bairro" value={dadosEndereco.bairro} onChange={e => handleEnderecoChange('bairro', e.target.value)} />
                                {/* @if($errors->has('bairro')) */}
                                <div class=" invalid-feedback">
                                    {/* {{ $errors-> first('bairro')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-3">
                                <label for="cidade">Cidade</label>
                                <input type="text" class="form-control {{ $errors->has('cidade') ? 'is-invalid' : '' }}"
                                    id="cidade" name="cidade" placeholder="Cidade" value={dadosEndereco.cidade} onChange={e => handleEnderecoChange('cidade', e.target.value)} />
                                {/* @if($errors->has('cidade')) */}
                                <div class=" invalid-feedback">
                                    {/* {{ $errors-> first('cidade')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-1">
                                <label for="uf">UF</label>
                                <input type="text" class="form-control text-uppercase {{ $errors->has('uf') ? 'is-invalid' : '' }}"
                                    id="uf" name="uf" placeholder="UF" value={dadosEndereco.uf} onChange={e => handleEnderecoChange('uf', e.target.value)} />
                                {/* @if($errors->has('uf')) */}
                                <div class=" invalid-feedback">
                                    {/* {{ $errors-> first('uf')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class=" form-group col-md-3">
                                <label for="email">Email de Contato</label>
                                <input type="email"
                                    class="form-control {{ $errors->has('email') ? 'is-invalid' : '' }}"
                                    id="email" name="email" placeholder="Email de Contato"
                                    value={email} onChange={e => setEmail(e.target.value)} />
                                {/* @if($errors->has('email')) */}
                                <div class=" invalid-feedback">
                                    {/* {{ $errors-> first('email')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-2">
                                <label for="telefone1">Telefone 1</label>
                                <input type="text" class="form-control {{ $errors->has('telefone1') ? 'is-invalid' : '' }}"
                                    id="telefone1" name="telefone1" placeholder="Telefone" value={telefone1} onChange={e => setTelefone1(e.target.value)} />
                                {/* @if($errors->has('telefone1')) */}
                                <div class=" invalid-feedback">
                                    {/* {{ $errors-> first('telefone1')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-2">
                                <label for="telefone2">Telefone 2</label>
                                <input type="text" class="form-control {{ $errors->has('telefone2') ? 'is-invalid' : '' }}"
                                    id="telefone2" name="telefone2" placeholder="Telefone" value={telefone2} onChange={e => setTelefone2(e.target.value)} />
                                {/* @if($errors->has('telefone2')) */}
                                <div class=" invalid-feedback">
                                    {/* {{ $errors-> first('telefone2')}} */}
                                </div>
                                {/* @endif */}
                            </div>
                            <div class="form-group col-md-5">
                                <label for="site">Site</label>
                                <input type="text" class="form-control"
                                    id="site" name="site" placeholder="Site" value={site} onChange={e => setSite(e.target.value)} />
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary">Salvar</button>
                    </form>
                </div>
            </div>

        </section>
    );

    /* @endsection
    
    @section('script')
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.16/jquery.mask.min.js"></script>
    
        <script type="text/javascript">
            var maskBehavior = function (val) {
                    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
                },
                options = {
                    onKeyPress: function (val, e, field, options) {
                        field.mask(maskBehavior.apply({}, arguments), options);
                    }
                };
    
            $(document).ready(() => {
                $('#telefone1').mask(maskBehavior, options);
                $('#telefone2').mask(maskBehavior, options);
                $('#numero').mask('#####');
                $('#uf').mask('SS');
                $('#cep').mask('00.000-000');        });
        </script>
    @endsection */


}