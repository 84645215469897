import React, { useEffect } from 'react';
import caminhoDoArquivo from '../../assets/loaderio-8e2b1504f6ce54f77b46876349825912.txt';

function ArquivoDownload() {


    useEffect(() => {
        // Lógica para ler o arquivo e transformá-lo em Blob
        const lerArquivoComoBlob = async () => {
            // Caminho para o arquivo
            
            try {
                // Carrega o arquivo como um Blob
                const resposta = await fetch(caminhoDoArquivo);
                const blob = await resposta.blob();

                // Cria uma URL temporária para o Blob
                const url = URL.createObjectURL(blob);

                // Cria um link para simular o download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'loaderio-8e2b1504f6ce54f77b46876349825912.txt'); // Define o nome do arquivo aqui
                document.body.appendChild(link);

                // Clique automaticamente no link para iniciar o download
                link.click();

                // Limpeza: remove o link e revoga a URL temporária após o download
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Erro ao ler o arquivo:', error);
            }
        };

        // Chama a função para ler o arquivo
        lerArquivoComoBlob();
    }, []);

    return (
        <div>
            <p>loaderio-8e2b1504f6ce54f77b46876349825912</p>
        </div>
    );
}

export default ArquivoDownload;
