import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CamposIncluir from "./pages/Campos/Incluir";
import CamposListar from "./pages/Campos/Listar";
import EmpresasIncluir from "./pages/Empresas/Incluir";
import EmpresasListar from "./pages/Empresas/Listar";
import Login from "./pages/Login";
import ArquivoDownload from "./pages/LoaderIo";

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Login />} />

                <Route path="/campos" element={<CamposListar />} />
                <Route path="/campos/incluir" element={<CamposIncluir />} />
                <Route path="/empresas" element={<EmpresasListar />} />
                <Route path="/empresas/incluir/:empresaId" element={<EmpresasIncluir />} />

                {/* Rota para servir o arquivo */}
                <Route path="/loaderio-8e2b1504f6ce54f77b46876349825912" element={<ArquivoDownload />} />

            </Routes>
        </BrowserRouter>
    );
}